import { ISeFlexibleRowFields } from 'types/generated/contentful';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import { ContentfulItem } from '../ContentfulItem';
import { Container } from '@mui/material';

// @TODO: Check if we have spacing in the theme
// Old theme had SPACE1 etc entries
const SPACING = {
  DEFAULT: 0,
  NONE: 0,
  SMALL: 8,
  MEDIUM: 24,
  LARGE: 48,
};

interface IRowBoxProps {
  height?: number | undefined;
  spacebefore?: number | undefined;
  backgroundimage?: string | undefined;
}
const RowBox = styled(Container)<IRowBoxProps>`
  height: ${(props) => (props.height ? props.height : 'auto')};
  width: 100%;
  margin-top: ${(props) => `${props.spacebefore}px`};
  background-image: ${(props) =>
    props.backgroundimage && `url(${props.backgroundimage}) `};
  background-size: 'cover';
  padding: 0;
`;

/**
 * FlexibleRow is a grid that tries to add the items into a shared row
 * So 1 item will be a 12 grid item, 2 items will be 6 grid items, etc
 * On xs it will be always a 12 grid item, so that content is behaving
 * like a floating element
 */

const isChildStage = (flexibleCells?: any[]) => {
  if (!flexibleCells || flexibleCells.length !== 1) return false;
  const childName = flexibleCells[0].sys?.contentType.sys.id;
  return ['ceStage', 'ceStageExclusiveBadges'].includes(childName);
};

export type FlexibleRowProps = ISeFlexibleRowFields;
export const FlexibleRow = (props: FlexibleRowProps) => {
  const { backgroundImage, height, spaceBefore, flexibleCells } = props;

  const marginTop = spaceBefore ? SPACING[spaceBefore] : SPACING.DEFAULT;
  const backgroundImageUrl = backgroundImage?.fields?.file?.url?.toString();

  const numberItems = (flexibleCells && flexibleCells.length) || 1;
  const contentItems =
    flexibleCells &&
    flexibleCells.map((item: any, index: number) => {
      const columnWidth = Math.max(Math.trunc(12 / numberItems), 3);

      const paddingRight = numberItems > 1 ? 2 : 0;

      return (
        <Grid item xs={12} md={columnWidth} key={index} pr={paddingRight}>
          <ContentfulItem key={index} {...item} />
        </Grid>
      );
    });

  return (
    <RowBox
      height={height}
      spacebefore={marginTop}
      backgroundimage={backgroundImageUrl}
      maxWidth={isChildStage(flexibleCells) ? false : 'lg'}
      disableGutters={true}
    >
      <Grid container>{contentItems}</Grid>
    </RowBox>
  );
};
